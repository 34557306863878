import { registerApplication } from 'single-spa'
import { RegistrationFunc } from './registration'
import { importSpa } from '../utils'

export const onboardingAdmin: RegistrationFunc = (globalCustomProps) => {
  registerApplication({
    name: 'third-party-ordering-onboarding-spa',
    activeWhen: '/onboarding/ordering-integrations',
    customProps: globalCustomProps,
    app: importSpa('third-party-ordering-onboarding-spa')
  })

  registerApplication({
    name: 'tds-onboarding-spa',
    activeWhen: '/onboarding/toast-delivery-service',
    customProps: globalCustomProps,
    // @ts-ignore
    app: importSpa('tds-onboarding-spa')
  })
}
