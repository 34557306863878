import { parseDate } from '@toasttab/buffet-pui-date-utilities'

export const bootstrap = async () => {
  return Promise.resolve()
}

/**
 * Adds the Wootric beacon to the DOM
 * @returns {Promise<void>}
 */
export const mount = async () => {
  let setupScript = document.createElement('script')
  setupScript.type = 'text/javascript'
  setupScript.async = true

  const wootricSettings = await fetchWootricData()
  setupScript.innerHTML = `
    window.wootricSettings = ${JSON.stringify(wootricSettings)};
  `
  document.body.appendChild(setupScript)

  let wootricsSource = document.createElement('script')
  wootricsSource.id = 'wootric-beacon'
  wootricsSource.type = 'text/javascript'
  wootricsSource.async = true

  wootricsSource.src = 'https://cdn.wootric.com/wootric-sdk.js'
  wootricsSource.onload = function () {
    // @ts-ignore
    if (window.wootric) {
      // @ts-ignore
      window.wootric('run')
    }
  }

  document.body.appendChild(wootricsSource)

  return Promise.resolve()
}

/**
 * Removes the Wootric beacon from the DOM
 */
export const unmount = async () => {
  const beacon = document.getElementById('wootric-beacon')

  if (beacon && document.body.contains(beacon)) {
    document.body.removeChild(beacon)
  }

  return Promise.resolve()
}

/**
 * Fetches the data needed to initialize the Wootric survey
 * @returns the wootrics settings object
 */
const fetchWootricData = async () => {
  try {
    const response = await fetch('/restaurants/admin/thirdPartyData')

    if (!response.ok) {
      console.error('Failed to fetch data:', response.statusText)
    }

    const data = await response.json()
    const posGoLiveDate = data?.posGoLiveDate
      ? (parseDate(data.posGoLiveDate, 'yyyyMMdd', 'en-US')?.getTime() || 0) /
        1000
      : 2147483647 // the max timestamp in 2040 since wootric doesn't like nulls

    return {
      email: data?.email,
      // wootric will only cue surveys by the created_at field, so we are populating it
      // with the pos go-live date or with the max epoch timestamp if the date is not available
      created_at: posGoLiveDate,
      account_token: 'NPS-97b68f45',
      properties: {
        restaurantGuid: data?.restaurantGuid,
        posGoLive_date: posGoLiveDate,
        userCreated_date: data?.createdAt
      }
    }
  } catch (error) {
    console.error('Failed to fetch data:', error)
  }

  return {}
}
