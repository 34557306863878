/* istanbul ignore file */
const removeBanquetLoadingIndicator = () => {
  const banquetLoadingIndicator = document.getElementById(
    'banquet-root-page-loading-indicator'
  )
  if (banquetLoadingIndicator?.parentNode) {
    banquetLoadingIndicator.parentNode.removeChild(banquetLoadingIndicator)
  }
}

export const handleError = () => {
  removeBanquetLoadingIndicator()
}
