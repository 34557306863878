import { RequestHandler } from '@apollo/client'
import { getSpaNameFromStackTrace } from './spa-name'

export const sharedClientReportingLink: RequestHandler = (
  operation,
  forward
) => {
  const spaName = getSpaNameFromStackTrace()
  const context = operation.getContext()
  operation.setContext({
    ...(context ?? {}),
    headers: {
      'apollographql-client-name': spaName ?? 'wex-banquet-root',
      'apollographql-client-version': spaName
        ? 'unknown'
        : process.env.PKG_VERSION,
      ...(context?.headers ?? {})
    }
  })
  return forward(operation)
}
