/**
 * Gets the default font size (based on user settings)
 * see https://medium.com/@vamptvo/pixels-vs-ems-users-do-change-font-size-5cfb20831773
 */

export type BrowserPreferences = {
  fontSize: string
  pixelRatio: number
  zoom: number
  darkMode: boolean
}

export const getBrowserPreferences = (): BrowserPreferences => {
  let fontSize = 'unknown'
  if (window.getComputedStyle) {
    // Ignore very old browsers.
    fontSize =
      window.getComputedStyle(document.documentElement)?.fontSize || 'unknown'
  }

  /*
  NOTES: 
  - zoom is not to be trusted for firefox (behaves strangely)
  - on iOS devices the only obvious way to zoom is this one (there is no obvious way to change just the text size)
  */
  const zoom = Math.round((window.outerWidth / window.innerWidth) * 100)

  /*
  NOTES: 
  - Chrome browser (on a Mac at least) has settings that are ignored in favour of the system settings (a bug perhaps)
  */
  const darkMode =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches

  return {
    fontSize,
    pixelRatio: window.devicePixelRatio,
    zoom,
    darkMode
  }
}
