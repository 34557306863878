/* istanbul ignore file */
import { addErrorHandler, getAppStatus, LOAD_ERROR } from 'single-spa'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { handleError } from '../errorHandlers'
import { EventHint, Hub } from '@sentry/types'

export let captureException: (() => void) | ((e: any) => void) = () => {}
export let captureMessage = (() => '') as Hub['captureMessage']

export interface SetupSentryProps {
  publicKey: string
  projectId: string
}

export async function setupSentry({ publicKey, projectId }: SetupSentryProps) {
  try {
    const environment = getCurrentEnvironment(window.location.hostname)
    const version = process.env.PKG_VERSION!
    const release = /^\d+$/.test(version)
      ? `wex-banquet-root@${version}.0.0`
      : version
    const Sentry = await System.import('@sentry/browser')
    if (environment !== 'dev' && Sentry) {
      // initialize the sentry project
      const dsn = `https://${publicKey}.ingest.sentry.io/${projectId}`
      const client = new Sentry.BrowserClient({
        dsn,
        environment,
        release,
        transport: Sentry.makeFetchTransport,
        stackParser: Sentry.defaultStackParser,
        integrations: Sentry.defaultIntegrations
      })
      const hub = new Sentry.Hub(client)
      hub.run((currentHub: Hub) => {
        currentHub.addBreadcrumb({
          message: 'WBR initiated'
        })
      })
      captureException = (...all: [any, EventHint?]) => {
        let eventId = ''
        hub.run((currentHub: Hub) => {
          eventId = currentHub.captureException(...all)
        })
        return eventId
      }
      captureMessage = (...all) => {
        let eventId = ''
        hub.run((currentHub: Hub) => {
          eventId = currentHub.captureMessage(...all)
        })
        return eventId
      }
    }
    // Add add single-spa error integration
    addErrorHandler((err) => {
      if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
        handleError()
        if (
          !err.message.includes('dev.eng.toastteam.com') &&
          captureException
        ) {
          captureException(err)
        } else {
          console.error(
            `
            ----------------------------------------------------------------------------------------------------------
            BANQUET WARNING: importMapOverrides for "${err.appOrParcelName}" is not configured correctly
            
            Helpful hints!
            
            - You may have a override in importMapOverrides development tools.
            - If you are running in local dev - try clearing the "toastweb/public/temp-import-map-overrides" folder.
            ----------------------------------------------------------------------------------------------------------
            
            `
          )
        }
        System.delete(System.resolve(err.appOrParcelName))
      } else {
        if (
          !err.message.includes('dev.eng.toastteam.com') &&
          captureException
        ) {
          captureException(err)
        } else {
          console.error(err)
        }
      }
    })
  } catch (error) {
    console.error('Error loading Sentry in WBR', error)
  }
}
