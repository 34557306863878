import { FFLookup } from '@toasttab/banquet-types'
import { LDClient } from 'launchdarkly-js-client-sdk'

export const NAV_SOUS_CHEF = 'nav-sous-chef'
export const NAV_NEW_EXPERIENCE = 'nav-new-experience'

export const isSousChef = (
  featureFlags?: FFLookup,
  ldClient?: LDClient
): boolean => {
  return (
    Boolean(featureFlags?.[NAV_SOUS_CHEF]) ||
    ldClient?.variation(NAV_NEW_EXPERIENCE, false) ||
    false
  )
}
