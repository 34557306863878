import { registerApplication } from 'single-spa'
import { importSpa } from '../utils'
import { RegistrationFunc } from './registration'
import { isOnProductionEnvironments } from '../utils/isOnProductionEnvironments'

const SPA_DEV_TOOLS = 'spa-dev-tools'

export const devTools: RegistrationFunc = (globalCustomProps) => {
  if (isOnProductionEnvironments()) {
    return
  }

  registerApplication({
    name: SPA_DEV_TOOLS,
    activeWhen() {
      return true
    },
    customProps: globalCustomProps,
    app: importSpa(SPA_DEV_TOOLS)
  })
}
