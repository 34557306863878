import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

const CAPMAN_PROD = 'https://capman-service-prod.herokuapp.com'
const CAPMAN_STAGING = 'https://capman-service-staging.herokuapp.com'

export function getCapmanOrigin(): string {
  return getCurrentEnvironment() === ToastEnvironment.PROD
    ? CAPMAN_PROD
    : CAPMAN_STAGING
}
