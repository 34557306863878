import { registerApplication } from 'single-spa'
import { importSpa } from '../utils'
import { RegistrationFunc } from './registration'

export const headerNotifications: RegistrationFunc = (globalCustomProps) => {
  // --------------------------------------
  // Define layout spa
  // --------------------------------------

  const flag: { message?: string } = globalCustomProps.ldClient?.variation(
    'cob-pumpkin-banner',
    false
  )

  if (flag.message) {
    registerApplication({
      name: 'restaurant-admin-header-notifications-spa',
      activeWhen: [
        '/restaurants/admin/home',
        '/restaurants/admin/toast-account/homepage'
      ],
      customProps: globalCustomProps,
      app: importSpa('restaurant-admin-header-notifications-spa')
    })
  }
}
