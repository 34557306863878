import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { graphql } from '@local/grouter'
import { captureException } from '../utils'

/**
 * This query is used to fetch the initial data
 * required to create the LaunchDarkly context
 * will over time replace all initial data requests in banquet instances
 */
export const initialDataQuery = graphql(/* GraphQL */ `
  query initialData {
    userPreferences {
      userFeatureOptIns {
        key
        value {
          value
          deferToDate
        }
      }
    }
    permissions {
      financialAccounts
    }
  }
`)

export async function fetchInitialData(
  apolloClient: ApolloClient<NormalizedCacheObject>
) {
  const initialData = await apolloClient.query({
    query: initialDataQuery,
    errorPolicy: 'all'
  })

  if (initialData.error?.graphQLErrors) {
    initialData.error?.graphQLErrors.forEach((err) => {
      captureException(err)
    })
  }

  return initialData
}
