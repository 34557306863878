import { ToastEnvironment } from '@toasttab/authentication-utils'

interface TealiumEvent {
  tealium_event: string
  environment?: ToastEnvironment
}

interface PageViewEvent extends TealiumEvent {
  tealium_event: 'page_view'
  site_name: 'toastweb'
  url: string
  normalized_path: string
  environment: ToastEnvironment
  user_guid: string
  first_name: string
  last_name: string
  phone_number: string
  email: string
  location_guid: string
}

interface UTag {
  view: (event: PageViewEvent, callback?: () => void) => void
  link: (event: TealiumEvent, callback?: () => void) => void
  track: (event: TealiumEvent, callback?: () => void) => void
}

type HardcodedProps = 'tealium_event' | 'site_name'
export type PageViewDetails = Omit<PageViewEvent, HardcodedProps>

export function trackPageView(event: PageViewDetails) {
  // the utag object is a global loaded by the Tealium Collect tag through the Tealium tag manager
  const utag = (window as any).utag as UTag

  if (utag) {
    utag.view({
      ...event,
      tealium_event: 'page_view',
      site_name: 'toastweb'
    })
  }
}
