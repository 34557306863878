import { GlobalCustomProps } from '@local/global-custom-props'

export const isNextBuffetTheme = (globalData: GlobalCustomProps) => {
  const localThemeOverrideValue = localStorage.getItem(
    'TOAST-isNextBuffetTheme'
  )
  if (localThemeOverrideValue) {
    return localThemeOverrideValue === 'true'
  }
  return !!globalData.featureFlags?.['wex-buffet-evolution-theme']
}
