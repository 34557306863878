import { registerApplication } from 'single-spa'
import { importSpa } from '../utils'
import { RegistrationFunc } from './registration'

export const settings: RegistrationFunc = (globalCustomProps) => {
  // --------------------------------------
  // Define layout spa
  // --------------------------------------
  registerApplication({
    name: 'spa-settings-management-layout',
    activeWhen: '/settings',
    customProps: globalCustomProps,
    app: importSpa('spa-settings-management-layout')
  })
}
