// global
import { registerApplication } from 'single-spa'
// local
import { GlobalCustomProps } from '@local/global-custom-props'
import { importSpa } from '../utils/SystemHelpers/importSpa'
import { RegistrationFunc } from './registration'

export const ecomm: RegistrationFunc = (customProps: GlobalCustomProps) => {
  // --------------------------------------
  // eComm Upsell SPA registration
  // --------------------------------------
  registerApplication({
    name: 'ecomm-shop-spa',
    activeWhen: '/customers/shop',
    customProps,
    app: importSpa('ecomm-shop-spa')
  })
}
