import { registerApplication } from 'single-spa'
import { featureFlagsMatch } from '@toasttab/banquet-match-utils'
import { importSpa, isToastUserRole } from '../utils'
import { RegistrationFunc } from './registration'

const AI_CHAT_SPA = 'ai-chat-spa'

export const aiChatBot: RegistrationFunc = (globalCustomProps) => {
  const isToastInternalUser = isToastUserRole(
    globalCustomProps.auth?.userInfo.roles || []
  )
  const flagMatch = featureFlagsMatch(globalCustomProps?.featureFlags ?? {})
  const isFeatureFlagEnabled = flagMatch('ds-2557-surface-ai-chat-bot')

  if (!isToastInternalUser || !isFeatureFlagEnabled) {
    return
  }

  registerApplication({
    name: AI_CHAT_SPA,
    activeWhen: '/restaurants/admin',
    customProps: globalCustomProps,
    app: importSpa(AI_CHAT_SPA)
  })
}
