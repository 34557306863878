import { GlobalCustomProps } from '@local/global-custom-props'
import { importSpa } from '../utils'
import { RegistrationFunc } from './registration'
import { registerApplication } from 'single-spa'

export const isSetupPageEnabled = (globalCustomProps: GlobalCustomProps) => {
  const { restaurantInfo, features } = globalCustomProps

  return (
    restaurantInfo?.isTestModeEnabled &&
    restaurantInfo?.isSetupPageEligible &&
    !features?.multiLocationManagementEnabled
  )
}

export const restaurantAdminHomePage: RegistrationFunc = (
  globalCustomProps
) => {
  // The onboarding setup page is only enabled if:
  // the feature flag is set to true, if the restaurant is in test mode,
  // the restaurant is ecomm OR self service AND not MLM
  const setupPageEnabled = isSetupPageEnabled(globalCustomProps)

  if (setupPageEnabled) {
    registerApplication({
      name: 'onboarding-setup-page-spa',
      activeWhen: '/restaurants/admin/home',
      customProps: globalCustomProps,
      app: importSpa('onboarding-setup-page-spa')
    })
  } else {
    registerApplication({
      name: 'restaurant-home-page-layout',
      activeWhen: '/restaurants/admin/home',
      customProps: {
        ...globalCustomProps,
        basename: 'restaurants/admin/home'
      },
      app: importSpa('restaurant-home-page-layout')
    })
  }
}
