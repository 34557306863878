import { ApolloLink } from '@apollo/client'
import { print } from 'graphql'
import {
  createPersistedQueryLink,
  type PersistedQueryLink
} from '@apollo/client/link/persisted-queries'

export const createPersistedQueriesLink = (
  opts: Omit<
    PersistedQueryLink.Options & {
      disableErrorLogging?: boolean
    },
    'generateHash' | 'sha256'
  > = {
    disableErrorLogging: false
  }
) => {
  const debugPersistedOperations =
    localStorage.getItem('debugPersistedOperations') === 'true'

  let devHeaders = {}
  try {
    if (process.env.NODE_ENV === 'development') {
      devHeaders = {
        'Toast-Dev-Allow-Arbitrary-Operations': true
      }
    }
  } catch (e) {
    // Ignore error
  }

  return ApolloLink.from([
    new ApolloLink((operation, forward) => {
      if (!(operation.query as any)?.['__meta__']?.['hash']) {
        if (!opts.disableErrorLogging) {
          console.error('Operation does not have a hash:', operation.query)
        }
        return forward(operation)
      }

      if (debugPersistedOperations) {
        console.log(
          'Persisted hash:',
          (operation.query as any)['__meta__']?.['hash']
        )
        console.log('Persisted operation:', print(operation.query))
        console.log('Persisted operation variables:', operation.variables)
      }

      operation.setContext({
        headers: {
          'Toast-Persistent-Query-Hash':
            (operation.query as any)['__meta__']?.['hash'] ?? 'unknown',
          ...devHeaders
        }
      })
      return forward(operation)
    }),
    createPersistedQueryLink({
      generateHash: (query) => {
        return (query as any)?.['__meta__']?.['hash'] ?? 'unknown'
      },
      useGETForHashedQueries: true,
      // Default behaviour: Disable the link on failed requests (i.e. if pqr is not supported yet)
      disable: (err) =>
        err.graphQLErrors?.some(
          ({ extensions, message }) =>
            extensions?.code === 'PERSISTED_QUERY_NOT_FOUND' ||
            message === 'PersistedQueryNotFound' ||
            message === 'PersistedQueryHeaderNotFound'
        ) ?? false,
      retry: (err) =>
        err.graphQLErrors?.some(
          ({ extensions, message }) =>
            extensions?.code === 'PERSISTED_QUERY_NOT_FOUND' ||
            message === 'PersistedQueryNotFound' ||
            message === 'PersistedQueryHeaderNotFound'
        ) ?? false,
      ...opts
    })
  ])
}
