import { registerApplication } from 'single-spa'
import { importSpa } from '../utils'
import { RegistrationFunc } from './registration'
import { isToastAdmin } from '../utils/isToastAdmin'

export const toastAdmin: RegistrationFunc = (globalCustomProps) => {
  registerApplication({
    name: 'spa-toast-administration',
    activeWhen: ({ pathname }) => isToastAdmin(pathname),
    customProps: globalCustomProps,
    app: importSpa('spa-toast-administration')
  })
}
